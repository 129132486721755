import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig'; // Importa la instancia configurada de Axios
import { Table, Container, Button, Modal, Form, Alert, Dropdown, Pagination, DropdownButton } from 'react-bootstrap';
import * as XLSX from 'xlsx';


const Ordenadores = () => {
  const [ordenadores, setOrdenadores] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [oficinas, setOficinas] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [filteredEmpleados, setFilteredEmpleados] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddEmpleadoModal, setShowAddEmpleadoModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [computerIdToDelete, setComputerIdToDelete] = useState(null);
  const [currentComputer, setCurrentComputer] = useState(null);
  const [modalError, setModalError] = useState('');
  const [addComputerError, setAddComputerError] = useState('');
  const [addEmpleadoError, setAddEmpleadoError] = useState('');
  const [importError, setImportError] = useState('');
  const [importSuccess, setImportSuccess] = useState('');
  const [success, setSuccess] = useState('');
  const [newComputer, setNewComputer] = useState({
    employeeId: '',
    modelo: '',
    numeroSerie: '',
    fechaCompra: '',
    status: '',
    oficina: '',
  });
  const [newEmpleado, setNewEmpleado] = useState({
    employeeId: '',
    departamentos: '',
    categoria: '',
    oficina: '',
    activo: true,
  });
  const [filters, setFilters] = useState({
    employeeId: '',
    modelo: '',
    numeroSerie: '',
    fechaCompra: '',
    status: '',
    oficina: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const fetchOrdenadores = async () => {
      try {
        const { data } = await axios.get('/ordenadores');
        setOrdenadores(data);
      } catch (error) {
        console.error('Error fetching ordenadores', error);
        if (error.response && error.response.data && error.response.data.message) {
          setModalError(error.response.data.message);
        } else {
          setModalError('Error fetching ordenadores');
        }
      }
    };

    const fetchModelos = async () => {
      try {
        const { data } = await axios.get('/modelos');
        setModelos(data);
      } catch (error) {
        console.error('Error fetching modelos', error);
        if (error.response && error.response.data && error.response.data.message) {
          setModalError(error.response.data.message);
        } else {
          setModalError('Error fetching modelos');
        }
      }
    };

    const fetchOficinas = async () => {
      try {
        const { data } = await axios.get('/oficinas');
        setOficinas(data);
      } catch (error) {
        console.error('Error fetching oficinas', error);
        if (error.response && error.response.data && error.response.data.message) {
          setModalError(error.response.data.message);
        } else {
          setModalError('Error fetching oficinas');
        }
      }
    };

    const fetchEmpleados = async () => {
      try {
        const { data } = await axios.get('/empleados');
        setEmpleados(data);
        setFilteredEmpleados(data);
      } catch (error) {
        console.error('Error fetching empleados', error);
        if (error.response && error.response.data && error.response.data.message) {
          setModalError(error.response.data.message);
        } else {
          setModalError('Error fetching empleados');
        }
      }
    };

    fetchOrdenadores();
    fetchModelos();
    fetchOficinas();
    fetchEmpleados();
  }, []);

  const handleAddComputer = () => {
    setNewComputer({
      employeeId: '',
      modelo: '',
      numeroSerie: '',
      fechaCompra: '',
      status: '',
      oficina: '',
    });
    setModalError(''); // Limpiar error previo
    setShowAddModal(true);
  };

  const handleEditComputer = (ordenador) => {
    setCurrentComputer(ordenador);
    setNewComputer({
      employeeId: ordenador.employeeId,
      modelo: ordenador.modelo,
      numeroSerie: ordenador.numeroSerie,
      fechaCompra: ordenador.fechaCompra.split('T')[0], // Formato de fecha para el input
      status: ordenador.status,
      oficina: ordenador.oficina._id,
    });
    setShowEditModal(true);
  };

  const handleAddComputerSubmit = async (e) => {
    e.preventDefault();

    if (newComputer.status === 'Asignado' && !newComputer.employeeId) {
      setAddComputerError('Employee ID es obligatorio cuando el estado es Asignado');
      return;
    }

    try {
      const { data } = await axios.post('/ordenadores', newComputer);
      const { data: updatedOrdenadores } = await axios.get('/ordenadores');
      setOrdenadores(updatedOrdenadores);
      setShowAddModal(false);
      setSuccess('Ordenador añadido con éxito');
      setNewComputer({
        employeeId: '',
        modelo: '',
        numeroSerie: '',
        fechaCompra: '',
        status: '',
        oficina: '',
      });
      setAddComputerError(''); // Limpiar error después de una operación exitosa
    } catch (error) {
      console.error('Error adding computer', error);
      if (error.response && error.response.data && error.response.data.message) {
        setAddComputerError(error.response.data.message);
      } else {
        setAddComputerError('Error adding computer');
      }
    }
  };


  const handleEditComputerSubmit = async (e) => {
    e.preventDefault();
  
    if (newComputer.status === 'Asignado' && !newComputer.employeeId) {
      setModalError('Employee ID es obligatorio cuando el estado es Asignado');
      return;
    }
  
    console.log('Payload enviado:', newComputer); // Agrega este console.log para verificar el payload
  
    try {
      const { data } = await axios.put(`/ordenadores/${currentComputer._id}`, newComputer);
      const { data: updatedOrdenadores } = await axios.get('/ordenadores');
      setOrdenadores(updatedOrdenadores);
      setShowEditModal(false);
      setSuccess('Ordenador actualizado con éxito');
      setNewComputer({
        employeeId: '',
        modelo: '',
        numeroSerie: '',
        fechaCompra: '',
        status: '',
        oficina: '',
      });
      setCurrentComputer(null);
      setModalError(''); // Limpiar error después de una operación exitosa
    } catch (error) {
      console.error('Error updating computer', error);
      if (error.response && error.response.data && error.response.data.message) {
        setModalError(error.response.data.message);
      } else {
        setModalError('Error updating computer');
      }
    }
  };
  

  const handleDeleteClick = (id) => {
    setComputerIdToDelete(id);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`/ordenadores/${computerIdToDelete}`);
      setOrdenadores(ordenadores.filter((ordenador) => ordenador._id !== computerIdToDelete));
      setShowDeleteModal(false);
      setSuccess('Ordenador eliminado con éxito');
    } catch (error) {
      console.error('Error deleting computer', error);
      setModalError(error.response.data.message);
      setShowDeleteModal(false);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setComputerIdToDelete(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewComputer({ ...newComputer, [name]: value });
  };

  const handleEmpleadoInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'employeeId' && !/^\d*$/.test(value)) {
      return;
    }
    setNewEmpleado({ ...newEmpleado, [name]: value });
  };

  const handleEmpleadoSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    const filtered = empleados.filter((empleado) =>
      empleado.employeeId.toLowerCase().includes(searchQuery) ||
      empleado.departamentos.join(', ').toLowerCase().includes(searchQuery) ||
      empleado.categoria.toLowerCase().includes(searchQuery) ||
      empleado.oficina.nombre.toLowerCase().includes(searchQuery)
    );
    setFilteredEmpleados(filtered);
  };

  const handleAddEmpleadoSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post('/empleados', newEmpleado);

      // Encontrar la oficina en la lista de oficinas para agregarla al estado de empleados
      const oficina = oficinas.find(ofi => ofi._id === data.oficina);
      data.oficina = oficina;

      setEmpleados([...empleados, data]);
      setFilteredEmpleados([...empleados, data]);
      setShowAddEmpleadoModal(false);
      setSuccess('Empleado añadido con éxito');
      setNewEmpleado({
        employeeId: '',
        departamentos: '',
        categoria: '',
        oficina: '',
        activo: true,
      });
      setAddEmpleadoError('');
    } catch (error) {
      console.error('Error adding empleado', error);
      if (error.response && error.response.data && error.response.data.message) {
        setAddEmpleadoError(error.response.data.message);
      } else {
        setAddEmpleadoError('Error adding empleado');
      }
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('/ordenadores/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // Manejar la respuesta después de la importación
      const { data: updatedOrdenadores } = await axios.get('/ordenadores');
      setOrdenadores(updatedOrdenadores);
      setShowImportModal(false);
      setImportSuccess('Importación exitosa');
      setImportError('');
    } catch (error) {
      console.error('Error importing data', error);
      if (error.response && error.response.data && error.response.data.message) {
        setImportError(error.response.data.message);
      } else {
        setImportError('Error importing data');
      }
      setImportSuccess('');
    }
  };

  const handleDownloadSample = () => {
    const sampleData = [
      { employeeId: 'E123', modelo: 'Modelo1', numeroSerie: '12345', fechaCompra: '2022-01-01', status: 'Asignado', oficina: 'Oficina1' },
      { employeeId: 'E124', modelo: 'Modelo2', numeroSerie: '12346', fechaCompra: '2022-02-01', status: 'Sin asignar', oficina: 'Oficina2' },
    ];
    const worksheet = XLSX.utils.json_to_sheet(sampleData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Ordenadores');
    XLSX.writeFile(workbook, 'ordenadores_muestra.xlsx');
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleClearFilter = (field) => {
    setFilters({ ...filters, [field]: '' });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (numberOfItems) => {
    setItemsPerPage(numberOfItems);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const filteredOrdenadores = ordenadores.filter((ordenador) => {
    return (
      (filters.employeeId === '' || ordenador.employeeId?.toLowerCase().includes(filters.employeeId.toLowerCase())) &&
      (filters.modelo === '' || ordenador.modelo?.toLowerCase().includes(filters.modelo.toLowerCase())) &&
      (filters.numeroSerie === '' || ordenador.numeroSerie?.toLowerCase().includes(filters.numeroSerie.toLowerCase())) &&
      (filters.fechaCompra === '' || ordenador.fechaCompra.includes(filters.fechaCompra)) &&
      (filters.status === '' || ordenador.status?.toLowerCase().includes(filters.status.toLowerCase())) &&
      (filters.oficina === '' || ordenador.oficina?.nombre?.toLowerCase().includes(filters.oficina.toLowerCase()))
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredOrdenadores.slice(indexOfFirstItem, indexOfLastItem);

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredOrdenadores.length / itemsPerPage);
    const pageNumbers = [];
    let startPage, endPage;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item className='mt-2' key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    return (
      <Pagination>
        {currentPage > 1 && (
          <>
            <Pagination.First className='mt-2' onClick={() => handlePageChange(1)} />
            <Pagination.Prev className='mt-2' onClick={() => handlePageChange(currentPage - 1)} />
          </>
        )}
        {pageNumbers}
        {currentPage < totalPages && (
          <>
            <Pagination.Next className='mt-2' onClick={() => handlePageChange(currentPage + 1)} />
            <Pagination.Last className='mt-2' onClick={() => handlePageChange(totalPages)} />
          </>
        )}
      </Pagination>
    );
  };

  const getFilterOptions = (field) => {
    const options = new Set(
      filteredOrdenadores.map(ordenador => field === 'oficina' ? ordenador[field]?.nombre : ordenador[field]).filter(Boolean)
    );
    return Array.from(options);
  };

  const employeeIdOptions = getFilterOptions('employeeId');
  const modeloOptions = getFilterOptions('modelo');
  const numeroSerieOptions = getFilterOptions('numeroSerie');
  const statusOptions = getFilterOptions('status');
  const oficinaOptions = getFilterOptions('oficina');

  return (
    <Container>
      <h1>Ordenadores</h1>
      {success && <Alert variant="success">{success}</Alert>}
      <Button variant="primary" onClick={handleAddComputer} className="mb-3">
        Añadir Ordenador
      </Button>
      <Button variant="secondary" onClick={() => setShowImportModal(true)} className="mb-3 ms-3">
        Importar Ordenadores
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Acciones</th>
            <th>
              <Dropdown>
                <Dropdown.Toggle variant={filters.employeeId ? "primary" : "link"} id="dropdown-employeeId" className="d-flex align-items-center justify-content-between">
                  Employee ID
                  {filters.employeeId && <Button variant="danger" size="sm" className="ms-1" onClick={() => handleClearFilter('employeeId')}>X</Button>}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Form.Control
                    type="text"
                    name="employeeId"
                    value={filters.employeeId}
                    onChange={handleFilterChange}
                    placeholder="Filtrar"
                    className="mx-3 my-2 w-auto"
                  />
                  {employeeIdOptions.map((option, index) => (
                    <Dropdown.Item key={index} onClick={() => setFilters({ ...filters, employeeId: option })}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th>
              <Dropdown>
                <Dropdown.Toggle variant={filters.modelo ? "primary" : "link"} id="dropdown-modelo" className="d-flex align-items-center justify-content-between">
                  Modelo
                  {filters.modelo && <Button variant="danger" size="sm" className="ms-1" onClick={() => handleClearFilter('modelo')}>X</Button>}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Form.Control
                    type="text"
                    name="modelo"
                    value={filters.modelo}
                    onChange={handleFilterChange}
                    placeholder="Filtrar"
                    className="mx-3 my-2 w-auto"
                  />
                  {modeloOptions.map((option, index) => (
                    <Dropdown.Item key={index} onClick={() => setFilters({ ...filters, modelo: option })}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th>
              <Dropdown>
                <Dropdown.Toggle variant={filters.numeroSerie ? "primary" : "link"} id="dropdown-numeroSerie" className="d-flex align-items-center justify-content-between">
                  Número de Serie
                  {filters.numeroSerie && <Button variant="danger" size="sm" className="ms-1" onClick={() => handleClearFilter('numeroSerie')}>X</Button>}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Form.Control
                    type="text"
                    name="numeroSerie"
                    value={filters.numeroSerie}
                    onChange={handleFilterChange}
                    placeholder="Filtrar"
                    className="mx-3 my-2 w-auto"
                  />
                  {numeroSerieOptions.map((option, index) => (
                    <Dropdown.Item key={index} onClick={() => setFilters({ ...filters, numeroSerie: option })}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th>
              <Dropdown>
                <Dropdown.Toggle variant={filters.fechaCompra ? "primary" : "link"} id="dropdown-fechaCompra" className="d-flex align-items-center justify-content-between">
                  Fecha de Compra
                  {filters.fechaCompra && <Button variant="danger" size="sm" className="ms-1" onClick={() => handleClearFilter('fechaCompra')}>X</Button>}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Form.Control
                    type="date"
                    name="fechaCompra"
                    value={filters.fechaCompra}
                    onChange={handleFilterChange}
                    className="mx-3 my-2 w-auto"
                  />
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th>
              <Dropdown>
                <Dropdown.Toggle variant={filters.status ? "primary" : "link"} id="dropdown-status" className="d-flex align-items-center justify-content-between">
                  Status
                  {filters.status && <Button variant="danger" size="sm" className="ms-1" onClick={() => handleClearFilter('status')}>X</Button>}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Form.Control
                    as="select"
                    name="status"
                    value={filters.status}
                    onChange={handleFilterChange}
                    className="mx-3 my-2 w-auto"
                  >
                    <option value="">Todos</option>
                    {statusOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th>
              <Dropdown>
                <Dropdown.Toggle variant={filters.oficina ? "primary" : "link"} id="dropdown-oficina" className="d-flex align-items-center justify-content-between">
                  Oficina
                  {filters.oficina && <Button variant="danger" size="sm" className="ms-1" onClick={() => handleClearFilter('oficina')}>X</Button>}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Form.Control
                    as="select"
                    name="oficina"
                    value={filters.oficina}
                    onChange={handleFilterChange}
                    className="mx-3 my-2 w-auto"
                  >
                    <option value="">Todas</option>
                    {oficinaOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </Dropdown.Menu>
              </Dropdown>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            currentItems.map((ordenador) => (
              <tr key={ordenador._id}>
                <td>
                  <Button variant="warning" onClick={() => handleEditComputer(ordenador)}>
                    Editar
                  </Button>{' '}
                  <Button variant="danger" onClick={() => handleDeleteClick(ordenador._id)}>
                    Eliminar
                  </Button>
                </td>
                <td>{ordenador.employeeId}</td>
                <td>{ordenador.modelo}</td>
                <td>{ordenador.numeroSerie}</td>
                <td>{ordenador.fechaCompra.split('T')[0]}</td>
                <td>{ordenador.status}</td>
                <td>{ordenador.oficina?.nombre}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center">
                No records found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <DropdownButton id="dropdown-basic-button" title={`Mostrar ${itemsPerPage}`}>
        <Dropdown.Item onClick={() => handleItemsPerPageChange(10)}>10</Dropdown.Item>
        <Dropdown.Item onClick={() => handleItemsPerPageChange(25)}>25</Dropdown.Item>
        <Dropdown.Item onClick={() => handleItemsPerPageChange(50)}>50</Dropdown.Item>
        <Dropdown.Item onClick={() => handleItemsPerPageChange(100)}>100</Dropdown.Item>
      </DropdownButton>
      {renderPagination()}

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Añadir Ordenador</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalError && <Alert variant="danger">{modalError}</Alert>}
          <Form onSubmit={handleAddComputerSubmit}>
            <Form.Group controlId="employeeId">
              <Form.Label>Employee ID</Form.Label>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {newComputer.employeeId ? newComputer.employeeId : 'Seleccione un empleado'}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Form.Control
                    type="text"
                    placeholder="Buscar empleado..."
                    onChange={handleEmpleadoSearch}
                    className="mx-3 my-2 w-auto"
                  />
                  <Dropdown.Item onClick={() => setNewComputer({ ...newComputer, employeeId: '' })}>
                    Ninguno
                  </Dropdown.Item>
                  {filteredEmpleados.map((empleado) => (
                    <Dropdown.Item
                      key={empleado._id}
                      onClick={() => setNewComputer({ ...newComputer, employeeId: empleado.employeeId })}
                    >
                      {empleado.employeeId} - {empleado.categoria} - {empleado.oficina.nombre}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Form.Control.Feedback type="invalid" style={{ display: modalError ? 'block' : 'none' }}>
                Employee ID es obligatorio cuando el estado es Asignado.
              </Form.Control.Feedback>
              <Button variant="link" onClick={() => setShowAddEmpleadoModal(true)}>
                Añadir nuevo empleado
              </Button>
            </Form.Group>
            <Form.Group controlId="modelo">
              <Form.Label>Modelo</Form.Label>
              <Form.Control
                as="select"
                name="modelo"
                value={newComputer.modelo}
                onChange={handleInputChange}
                required
              >
                <option value="">Seleccione un modelo</option>
                {modelos.map((modelo) => (
                  <option key={modelo._id} value={modelo.nombre}>
                    {modelo.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="numeroSerie">
              <Form.Label>Número de Serie</Form.Label>
              <Form.Control
                type="text"
                name="numeroSerie"
                value={newComputer.numeroSerie}
                onChange={handleInputChange}
                required
                isInvalid={modalError.includes('Número de serie ya existe')}
              />
              <Form.Control.Feedback type="invalid">
                Número de serie ya existe.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="fechaCompra">
              <Form.Label>Fecha de Compra</Form.Label>
              <Form.Control
                type="date"
                name="fechaCompra"
                value={newComputer.fechaCompra}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={newComputer.status}
                onChange={handleInputChange}
                required
              >
                <option value="">Seleccione un estado</option>
                <option value="Asignado">Asignado</option>
                <option value="Sin asignar">Sin asignar</option>
                <option value="En mantenimiento">En mantenimiento</option>
                <option value="Fuera de servicio">Fuera de servicio</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="oficina">
              <Form.Label>Oficina</Form.Label>
              <Form.Control
                as="select"
                name="oficina"
                value={newComputer.oficina}
                onChange={handleInputChange}
                required
              >
                <option value="">Seleccione una oficina</option>
                {oficinas.map((oficina) => (
                  <option key={oficina._id} value={oficina._id}>
                    {oficina.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Añadir Ordenador
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Ordenador</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addComputerError && <Alert variant="danger">{addComputerError}</Alert>}
          <Form onSubmit={handleEditComputerSubmit}>
            <Form.Group controlId="employeeId">
              <Form.Label>Employee ID</Form.Label>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {newComputer.employeeId ? newComputer.employeeId : 'Seleccione un empleado'}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Form.Control
                    type="text"
                    placeholder="Buscar empleado..."
                    onChange={handleEmpleadoSearch}
                    className="mx-3 my-2 w-auto"
                  />
                  <Dropdown.Item onClick={() => setNewComputer({ ...newComputer, employeeId: '' })}>
                    Ninguno
                  </Dropdown.Item>
                  {filteredEmpleados.map((empleado) => (
                    <Dropdown.Item
                      key={empleado._id}
                      onClick={() => setNewComputer({ ...newComputer, employeeId: empleado.employeeId })}
                    >
                      {empleado.employeeId} - {empleado.categoria} - {empleado.oficina.nombre}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Form.Control.Feedback type="invalid" style={{ display: modalError ? 'block' : 'none' }}>
                Employee ID es obligatorio cuando el estado es Asignado.
              </Form.Control.Feedback>
              <Button variant="link" onClick={() => setShowAddEmpleadoModal(true)}>
                Añadir nuevo empleado
              </Button>
            </Form.Group>
            <Form.Group controlId="modelo">
              <Form.Label>Modelo</Form.Label>
              <Form.Control
                as="select"
                name="modelo"
                value={newComputer.modelo}
                onChange={handleInputChange}
                required
              >
                <option value="">Seleccione un modelo</option>
                {modelos.map((modelo) => (
                  <option key={modelo._id} value={modelo.nombre}>
                    {modelo.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="numeroSerie">
              <Form.Label>Número de Serie</Form.Label>
              <Form.Control
                type="text"
                name="numeroSerie"
                value={newComputer.numeroSerie}
                onChange={handleInputChange}
                required
                isInvalid={modalError.includes('Número de serie ya existe')}
              />
              <Form.Control.Feedback type="invalid">
                Número de serie ya existe.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="fechaCompra">
              <Form.Label>Fecha de Compra</Form.Label>
              <Form.Control
                type="date"
                name="fechaCompra"
                value={newComputer.fechaCompra}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={newComputer.status}
                onChange={handleInputChange}
                required
              >
                <option value="">Seleccione un estado</option>
                <option value="Asignado">Asignado</option>
                <option value="Sin asignar">Sin asignar</option>
                <option value="En mantenimiento">En mantenimiento</option>
                <option value="Fuera de servicio">Fuera de servicio</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="oficina">
              <Form.Label>Oficina</Form.Label>
              <Form.Control
                as="select"
                name="oficina"
                value={newComputer.oficina}
                onChange={handleInputChange}
                required
              >
                <option value="">Seleccione una oficina</option>
                {oficinas.map((oficina) => (
                  <option key={oficina._id} value={oficina._id}>
                    {oficina.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Actualizar Ordenador
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showAddEmpleadoModal} onHide={() => setShowAddEmpleadoModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Añadir Empleado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addEmpleadoError && <Alert variant="danger">{addEmpleadoError}</Alert>}
          <Form onSubmit={handleAddEmpleadoSubmit}>
            <Form.Group controlId="employeeId">
              <Form.Label>Employee ID</Form.Label>
              <Form.Control
                type="text"
                name="employeeId"
                value={newEmpleado.employeeId}
                onChange={handleEmpleadoInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="departamentos">
              <Form.Label>Departamentos</Form.Label>
              <Form.Control
                type="text"
                name="departamentos"
                value={newEmpleado.departamentos}
                onChange={(e) => setNewEmpleado({ ...newEmpleado, departamentos: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="categoria">
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                type="text"
                name="categoria"
                value={newEmpleado.categoria}
                onChange={(e) => setNewEmpleado({ ...newEmpleado, categoria: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="oficina">
              <Form.Label>Oficina</Form.Label>
              <Form.Control
                as="select"
                name="oficina"
                value={newEmpleado.oficina}
                onChange={(e) => setNewEmpleado({ ...newEmpleado, oficina: e.target.value })}
                required
              >
                <option value="">Seleccione una oficina</option>
                {oficinas.map((oficina) => (
                  <option key={oficina._id} value={oficina._id}>
                    {oficina.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="activo">
              <Form.Check
                type="checkbox"
                label="Activo"
                name="activo"
                checked={newEmpleado.activo}
                onChange={(e) => setNewEmpleado({ ...newEmpleado, activo: e.target.checked })}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Añadir Empleado
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de que desea eliminar este ordenador?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showImportModal} onHide={() => setShowImportModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Importar Ordenadores</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {importError && <Alert variant="danger">{importError}</Alert>}
          {importSuccess && <Alert variant="success">{importSuccess}</Alert>}
          <Button variant="info" onClick={handleDownloadSample}>
            Descargar Archivo de Muestra
          </Button>
          <Form.Group controlId="fileUpload" className="mt-3">
            <Form.Label>Subir Archivo Excel</Form.Label>
            <Form.Control type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
          </Form.Group>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Ordenadores;
