import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig'; // Importa la instancia configurada de Axios
import { Table, Container, Button, Modal, Form, Alert } from 'react-bootstrap';

const Oficinas = () => {
  const [oficinas, setOficinas] = useState([]);
  const [stats, setStats] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newOficina, setNewOficina] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchOficinas = async () => {
      try {
        const { data } = await axios.get('/oficinas');
        setOficinas(data);
      } catch (error) {
        console.error('Error fetching oficinas', error);
        if (error.response && error.response.data && error.response.data.message) {
          setError(error.response.data.message);
        } else {
          setError('Error fetching oficinas');
        }
      }
    };

    const fetchStats = async () => {
      try {
        const { data } = await axios.get('/oficinas/stats');
        setStats(data);
      } catch (error) {
        console.error('Error fetching oficina stats', error);
        if (error.response && error.response.data && error.response.data.message) {
          setError(error.response.data.message);
        } else {
          setError('Error fetching oficina stats');
        }
      }
    };

    fetchOficinas();
    fetchStats();
  }, []);

  const handleAddOficina = () => {
    setNewOficina('');
    setError(''); // Limpiar error previo
    setShowAddModal(true);
  };

  const handleAddOficinaSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post('/oficinas', { nombre: newOficina });
      setOficinas([...oficinas, data]);
      const { data: updatedStats } = await axios.get('/oficinas/stats');
      setStats(updatedStats);
      setShowAddModal(false);
      setSuccess('Oficina añadida con éxito');
      setNewOficina('');
    } catch (error) {
      console.error('Error adding oficina', error);
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Error adding oficina');
      }
    }
  };

  return (
    <Container>
      <h1>Oficinas</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Button variant="primary" onClick={handleAddOficina} className="mb-3">
        Añadir Oficina
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Asignados</th>
            <th>Sin asignar</th>
            <th>En mantenimiento</th>
            <th>Fuera de servicio</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {stats.map((stat, index) => (
            <tr key={index}>
              <td>{stat.oficina}</td>
              <td>{stat.asignados}</td>
              <td>{stat.sinAsignar}</td>
              <td>{stat.enMantenimiento}</td>
              <td>{stat.fueraDeServicio}</td>
              <td>{stat.total}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Añadir Oficina</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddOficinaSubmit}>
            <Form.Group controlId="nombre">
              <Form.Label>Nombre de la Oficina</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                value={newOficina}
                onChange={(e) => setNewOficina(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Añadir Oficina
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Oficinas;
