import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig'; // Importa la instancia configurada de Axios
import { Table, Container, Button, Modal, Form, Alert } from 'react-bootstrap';

const ModelosEstandarizados = () => {
  const [modelos, setModelos] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentModel, setCurrentModel] = useState(null);
  const [modelIdToDelete, setModelIdToDelete] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [newModel, setNewModel] = useState({
    nombre: '',
    marca: '',
    modelo: '',
    ram: '',
    procesador: '',
    almacenamiento: '',
  });

  useEffect(() => {
    const fetchModelos = async () => {
      try {
        const { data } = await axios.get('/modelos');
        setModelos(data);
      } catch (error) {
        console.error('Error fetching modelos', error);
        if (error.response && error.response.data && error.response.data.message) {
          setError(error.response.data.message);
        } else {
          setError('Error fetching modelos');
        }
      }
    };

    fetchModelos();
  }, []);

  const handleAddModel = () => {
    setNewModel({
      nombre: '',
      marca: '',
      modelo: '',
      ram: '',
      procesador: '',
      almacenamiento: '',
    });
    setError(''); // Limpiar error previo
    setShowAddModal(true);
  };

  const handleEditModel = (model) => {
    setCurrentModel(model);
    setNewModel({
      nombre: model.nombre,
      marca: model.marca,
      modelo: model.modelo,
      ram: model.ram,
      procesador: model.procesador,
      almacenamiento: model.almacenamiento,
    });
    setShowEditModal(true);
  };

  const handleDeleteModel = (id) => {
    setModelIdToDelete(id);
    setShowDeleteModal(true);
  };

  const handleAddModelSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post('/modelos', newModel);
      setModelos([...modelos, data]);
      setShowAddModal(false);
      setSuccess('Modelo añadido con éxito');
      setNewModel({
        nombre: '',
        marca: '',
        modelo: '',
        ram: '',
        procesador: '',
        almacenamiento: '',
      });
    } catch (error) {
      console.error('Error adding model', error);
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Error adding model');
      }
    }
  };

  const handleEditModelSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.put(`/modelos/${currentModel._id}`, newModel);
      const updatedModelos = modelos.map((model) =>
        model._id === currentModel._id ? data : model
      );
      setModelos(updatedModelos);
      setShowEditModal(false);
      setSuccess('Modelo actualizado con éxito');
      setNewModel({
        nombre: '',
        marca: '',
        modelo: '',
        ram: '',
        procesador: '',
        almacenamiento: '',
      });
      setCurrentModel(null);
    } catch (error) {
      console.error('Error updating model', error);
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Error updating model');
      }
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`/modelos/${modelIdToDelete}`);
      setModelos(modelos.filter((model) => model._id !== modelIdToDelete));
      setShowDeleteModal(false);
      setSuccess('Modelo eliminado con éxito');
    } catch (error) {
      console.error('Error deleting model', error);
      setError(error.response.data.message);
      setShowDeleteModal(false);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setModelIdToDelete(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewModel({ ...newModel, [name]: value });
  };

  return (
    <Container>
      <h1>Modelos Estandarizados</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Button variant="primary" onClick={handleAddModel} className="mb-3">
        Añadir Modelo
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Acciones</th>
            <th>Nombre</th>
            <th>Marca</th>
            <th>Modelo</th>
            <th>RAM</th>
            <th>Procesador</th>
            <th>Almacenamiento</th>
          </tr>
        </thead>
        <tbody>
          {modelos.map((model) => (
            <tr key={model._id}>
              <td>
                <Button variant="warning" onClick={() => handleEditModel(model)}>
                  Editar
                </Button>{' '}
                <Button variant="danger" onClick={() => handleDeleteModel(model._id)}>
                  Eliminar
                </Button>
              </td>
              <td>{model.nombre}</td>
              <td>{model.marca}</td>
              <td>{model.modelo}</td>
              <td>{model.ram}</td>
              <td>{model.procesador}</td>
              <td>{model.almacenamiento}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Añadir Modelo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddModelSubmit}>
            <Form.Group controlId="nombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                value={newModel.nombre}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="marca">
              <Form.Label>Marca</Form.Label>
              <Form.Control
                type="text"
                name="marca"
                value={newModel.marca}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="modelo">
              <Form.Label>Modelo</Form.Label>
              <Form.Control
                type="text"
                name="modelo"
                value={newModel.modelo}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="ram">
              <Form.Label>RAM</Form.Label>
              <Form.Control
                type="text"
                name="ram"
                value={newModel.ram}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="procesador">
              <Form.Label>Procesador</Form.Label>
              <Form.Control
                type="text"
                name="procesador"
                value={newModel.procesador}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="almacenamiento">
              <Form.Label>Almacenamiento</Form.Label>
              <Form.Control
                type="text"
                name="almacenamiento"
                value={newModel.almacenamiento}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Añadir Modelo
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Modelo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditModelSubmit}>
            <Form.Group controlId="nombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                value={newModel.nombre}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="marca">
              <Form.Label>Marca</Form.Label>
              <Form.Control
                type="text"
                name="marca"
                value={newModel.marca}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="modelo">
              <Form.Label>Modelo</Form.Label>
              <Form.Control
                type="text"
                name="modelo"
                value={newModel.modelo}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="ram">
              <Form.Label>RAM</Form.Label>
              <Form.Control
                type="text"
                name="ram"
                value={newModel.ram}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="procesador">
              <Form.Label>Procesador</Form.Label>
              <Form.Control
                type="text"
                name="procesador"
                value={newModel.procesador}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="almacenamiento">
              <Form.Label>Almacenamiento</Form.Label>
              <Form.Control
                type="text"
                name="almacenamiento"
                value={newModel.almacenamiento}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Actualizar Modelo
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de que desea eliminar este modelo?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ModelosEstandarizados;
