import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome, faUsers, faDownload, faClipboardList, faBuilding, faBell, faProjectDiagram,
  faAngleDoubleRight, faAngleDoubleLeft,
  faUserTie, faLaptopCode
} from '@fortawesome/free-solid-svg-icons';
import logo from "./logo_msi.png"

const Sidebar = ({ userRole, onToggleSidebar }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleToggle = () => {
    setCollapsed(!collapsed);
    onToggleSidebar();
  };

  return (
    <div className={`sidebar p-3 ${collapsed ? 'collapsed ms-2' : ''}`}>
      <div className={`${collapsed ? 'mb-5' : ''}`}>
        <button className={`toggle-btn btn btn-dark ${collapsed ? '' : ''}`} onClick={handleToggle}>
          <FontAwesomeIcon icon={collapsed ? faAngleDoubleRight : faAngleDoubleLeft} />
        </button>
      </div>
      <Nav defaultActiveKey="/" className="flex-column">
        <LinkContainer to="/" exact>
          <Nav.Link className="nav-item-custom">
            <FontAwesomeIcon icon={faHome} className={`fa-icon`} />
            <span className="ml-2">Dashboard</span>
          </Nav.Link>
        </LinkContainer>
        {userRole === 'Admin' && (
          <LinkContainer to="/usuarios">
            <Nav.Link className="nav-item-custom">
              <FontAwesomeIcon icon={faUsers} className="fa-icon fa-fw" />
              <span className="ml-2">Usuarios</span>
            </Nav.Link>
          </LinkContainer>
        )}
        {userRole === 'Admin' && (
          <LinkContainer to="/empleados">
            <Nav.Link className="nav-item-custom">
              <FontAwesomeIcon icon={faUserTie} className="fa-icon fa-fw" />
              <span className="ml-2">Empleados</span>
            </Nav.Link>
          </LinkContainer>
        )}
        {userRole === 'Admin' && (
          <LinkContainer to="/modelos">
            <Nav.Link className="nav-item-custom">
              <FontAwesomeIcon icon={faClipboardList} className="fa-icon fa-fw" />
              <span className="ml-2">Modelos Estandarizados</span>
            </Nav.Link>
          </LinkContainer>
        )}
        <LinkContainer to="/ordenadores">
          <Nav.Link className="nav-item-custom">
            <FontAwesomeIcon icon={faLaptopCode} className="fa-icon fa-fw" />
            <span className="ml-2">Ordenadores</span>
          </Nav.Link>
        </LinkContainer>
        <LinkContainer to="/relacion-modelos-departamentos">
          <Nav.Link className="nav-item-custom">
            <FontAwesomeIcon icon={faProjectDiagram} className="fa-icon fa-fw" />
            <span className="ml-2">Relación Modelos-Departamentos</span>
          </Nav.Link>
        </LinkContainer>
        <LinkContainer to="/oficinas">
          <Nav.Link className="nav-item-custom">
            <FontAwesomeIcon icon={faBuilding} className="fa-icon fa-fw" />
            <span className="ml-2">Oficinas</span>
          </Nav.Link>
        </LinkContainer>
        {userRole === 'Admin' && (
          <LinkContainer to="/alertas">
            <Nav.Link className="nav-item-custom">
              <FontAwesomeIcon icon={faBell} className="fa-icon fa-fw" />
              <span className="ml-2">Alertas</span>
            </Nav.Link>
          </LinkContainer>
        )}
        {userRole === 'Admin' && (
          <LinkContainer to="/exportar">
            <Nav.Link className="nav-item-custom">
              <FontAwesomeIcon icon={faDownload} className="fa-icon fa-fw" />
              <span className="ml-2">Exportar Datos</span>
            </Nav.Link>
          </LinkContainer>
        )}
      </Nav>
      <img src={logo} className={`${collapsed ? 'our-brand-collapsed mt-3 ms-4' : 'our-brand ms-5 ps-5 mt-5'}`}/>
    </div>
  );
};

export default Sidebar;
