import React, { useState } from 'react';
import axios from '../axiosConfig';
import { Button, Container, Form, Row, Col, Alert } from 'react-bootstrap';
import * as XLSX from 'xlsx';

const Exportar = () => {
  const [exportType, setExportType] = useState('empleados');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleExport = async () => {
    setError('');
    setSuccess('');

    try {
      const { data } = await axios.get(`/${exportType}`);
      if (data.length === 0) {
        setError('No hay datos para exportar.');
        return;
      }

      // Process data to include nested properties and remove __v
      const processedData = data.map(item => {
        const newItem = { ...item };

        if (newItem.oficina && newItem.oficina.nombre) {
          newItem.oficina = newItem.oficina.nombre;
        } else if (newItem.oficina) {
          newItem.oficina = 'Sin Oficina';
        }

        if (newItem.departamentos) {
          newItem.departamentos = newItem.departamentos.join(', ');
        }

        if (newItem.historialEmpleados) {
          newItem.historialEmpleados = newItem.historialEmpleados.map(emp => emp.employeeId || emp).join(', ');
        }

        delete newItem.__v;
        delete newItem._id;

        return newItem;
      });

      const worksheet = XLSX.utils.json_to_sheet(processedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, exportType);
      XLSX.writeFile(workbook, `${exportType}.xlsx`);

      setSuccess('Datos exportados con éxito.');
    } catch (error) {
      console.error('Error exporting data', error);
      setError('Error exportando datos.');
    }
  };

  return (
    <Container>
      <h1>Exportar Datos</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Form>
        <Form.Group as={Row} controlId="formExportType">
          <Form.Label column sm={2}>
            Tipo de Exportación
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              value={exportType}
              onChange={(e) => setExportType(e.target.value)}
            >
              <option value="empleados">Empleados</option>
              <option value="ordenadores">Ordenadores</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Button variant="primary" onClick={handleExport} className="mt-3">
          Exportar Datos
        </Button>
      </Form>
    </Container>
  );
};

export default Exportar;
