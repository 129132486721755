import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig'; // Importa la instancia configurada de Axios
import { Table, Container, Button, Modal, Alert, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [show, setShow] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(''); // Estado para el mensaje de éxito
  const [currentUser, setCurrentUser] = useState(null); // Añadir estado para el usuario actual
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const { data } = await axios.get('/usuarios');
        setUsuarios(data);
      } catch (error) {
        console.error('Error fetching usuarios', error);
      }
    };

    fetchUsuarios();
  }, []);

  const handleAddUser = () => {
    navigate('/add-usuario');
  };

  const handleEditUser = (user) => {
    setCurrentUser(user);
    setShow(true);
  };

  const handleDeleteClick = (id) => {
    setUserIdToDelete(id);
    setShow(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const { data } = await axios.delete(`/usuarios/${userIdToDelete}`);
      if (data.message === 'No puedes eliminarte a ti mismo') {
        setError(data.message);
      } else {
        setUsuarios(usuarios.filter((usuario) => usuario._id !== userIdToDelete));
        setSuccess('Usuario eliminado con éxito'); // Mensaje de éxito
      }
      setShow(false);
    } catch (error) {
      setError(error.response.data.message);
      setShow(false);
    }
  };

  const handleDeleteCancel = () => {
    setShow(false);
    setUserIdToDelete(null);
  };

  const handleSaveUser = async () => {
    try {
      const { data } = await axios.put(`/usuarios/${currentUser._id}`, currentUser);
      setUsuarios(usuarios.map((user) => (user._id === currentUser._id ? data : user)));
      setShow(false);
    } catch (error) {
      setError(error.response.data.message);
      setShow(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  return (
    <Container>
      <h1>Usuarios</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>} {/* Mostrar mensaje de éxito */}
      <Button variant="primary" onClick={handleAddUser} className="mb-3">
        Añadir Usuario
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Acciones</th>
            <th>Nombre</th>
            <th>Rol</th>
            <th>Email</th>
            <th>Creado en</th>
            <th>Actualizado en</th>
          </tr>
        </thead>
        <tbody>
          {usuarios.map((usuario) => (
            <tr key={usuario._id}>
              <td>
                <Button variant="warning" onClick={() => handleEditUser(usuario)}>Editar</Button>{' '}
                <Button variant="danger" onClick={() => handleDeleteClick(usuario._id)}>Eliminar</Button>
              </td>
              <td>{usuario.nombre}</td>
              <td>{usuario.rol}</td>
              <td>{usuario.email}</td>
              <td>{new Date(usuario.createdAt).toLocaleString()}</td>
              <td>{new Date(usuario.updatedAt).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={show} onHide={handleDeleteCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{currentUser ? 'Editar Usuario' : 'Confirmar Eliminación'}</Modal.Title>
        </Modal.Header>
        {currentUser ? (
          <Modal.Body>
            <Form>
              <Form.Group controlId="nombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  name="nombre"
                  value={currentUser.nombre}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="rol">
                <Form.Label>Rol</Form.Label>
                <Form.Control
                  as="select"
                  name="rol"
                  value={currentUser.rol}
                  onChange={handleChange}
                  required
                >
                  <option value="Admin">Admin</option>
                  <option value="User">User</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={currentUser.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Button variant="primary" onClick={handleSaveUser} className="mt-3">
                Guardar Usuario
              </Button>
            </Form>
          </Modal.Body>
        ) : (
          <Modal.Body>¿Está seguro de que desea eliminar este usuario?</Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>Cancelar</Button>
          {!currentUser && (
            <Button variant="danger" onClick={handleDeleteConfirm}>Eliminar</Button>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Usuarios;
