import React from 'react';
import { Navbar, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import fever from './fever_test.png';
import './TopBar.css';  // Asegúrate de importar el archivo CSS

const TopBar = ({ setAuth, onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  return (
    <Navbar bg="dark" variant="dark" className="justify-content-between">
    <Navbar.Brand className="d-flex align-items-center">
      <img src={fever} alt="Brand Logo" className="brand-logo ms-3" />
      <h3 className="mb-0 ms-2 ml-2">| Stock Management</h3>
    </Navbar.Brand>
    <Button variant="outline-light mx-3" onClick={handleLogout}>
      Cerrar Sesión
    </Button>
  </Navbar>
  );
};

export default TopBar;
