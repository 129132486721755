import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { Container, Table, Button, Modal, Form, Alert } from 'react-bootstrap';

const RelacionModelosDepartamentos = () => {
  const [modelos, setModelos] = useState([]);
  const [departamentosCategorias, setDepartamentosCategorias] = useState([]);
  const [asignaciones, setAsignaciones] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [currentAsignacion, setCurrentAsignacion] = useState(null);
  const [newAsignacion, setNewAsignacion] = useState({ departamento: '', categoria: '', modelo: '' });
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const modelosRes = await axios.get('/modelos');
        const empleadosRes = await axios.get('/empleados');
        const asignacionesRes = await axios.get('/asignaciones');

        const departamentosCategoriasSet = new Set();

        empleadosRes.data.forEach(empleado => {
          empleado.departamentos.forEach(dep => {
            departamentosCategoriasSet.add(JSON.stringify({ departamento: dep, categoria: empleado.categoria }));
          });
        });

        const departamentosCategoriasArray = Array.from(departamentosCategoriasSet).map(dc => JSON.parse(dc));

        setModelos(modelosRes.data);
        setDepartamentosCategorias(departamentosCategoriasArray);
        setAsignaciones(asignacionesRes.data);
      } catch (error) {
        setError('Error fetching data');
      }
    };

    fetchData();
  }, []);

  const handleAsignacionChange = (field, value) => {
    setNewAsignacion({ ...newAsignacion, [field]: value });
  };

  const handleSaveAsignacion = async () => {
    try {
      if (editing) {
        await axios.put(`/asignaciones/${currentAsignacion._id}`, newAsignacion);
      } else {
        await axios.post('/asignaciones', newAsignacion);
      }

      const asignacionesRes = await axios.get('/asignaciones');
      setAsignaciones(asignacionesRes.data);
      setSuccess('Asignación guardada con éxito');
      setError('');
      setShowModal(false);
    } catch (error) {
      setError(error.response.data.message);
      setSuccess('');
    }
  };

  const handleEditAsignacion = (asignacion) => {
    setCurrentAsignacion(asignacion);
    setNewAsignacion({
      departamento: asignacion.departamento,
      categoria: asignacion.categoria,
      modelo: asignacion.modelo._id,
    });
    setEditing(true);
    setShowModal(true);
  };

  const handleDeleteAsignacion = async (id) => {
    try {
      await axios.delete(`/asignaciones/${id}`);
      const asignacionesRes = await axios.get('/asignaciones');
      setAsignaciones(asignacionesRes.data);
      setSuccess('Asignación eliminada con éxito');
      setError('');
    } catch (error) {
      setError('Error eliminando la asignación');
      setSuccess('');
    }
  };

  const getUniqueDepartments = () => {
    const allDepartments = departamentosCategorias.map(dc => dc.departamento);
    return Array.from(new Set(allDepartments));
  };

  const getCategoriesForDepartment = (department) => {
    return departamentosCategorias
      .filter(dc => dc.departamento === department)
      .map(dc => dc.categoria);
  };

  const getUnassignedDepartments = () => {
    return departamentosCategorias.filter(dc => 
      !asignaciones.some(asign => asign.departamento === dc.departamento && asign.categoria === dc.categoria)
    );
  };

  return (
    <Container>
      <h1>Relación Modelos-Departamentos</h1>
      {success && <Alert variant="success">{success}</Alert>}
      <Button variant="primary" onClick={() => {
        setEditing(false);
        setNewAsignacion({ departamento: '', categoria: '', modelo: '' });
        setShowModal(true);
      }} className="mb-3">
        Añadir Asignación
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Acciones</th>
            <th>Departamento</th>
            <th>Categoría</th>
            <th>Modelo</th>
          </tr>
        </thead>
        <tbody>
          {asignaciones.map((asignacion) => (
            <tr key={asignacion._id}>
              <td>
                <Button variant="warning" onClick={() => handleEditAsignacion(asignacion)}>Editar</Button>{' '}
                <Button variant="danger" onClick={() => handleDeleteAsignacion(asignacion._id)}>Eliminar</Button>
              </td>
              <td>{asignacion.departamento}</td>
              <td>{asignacion.categoria}</td>
              <td>{asignacion.modelo?.nombre}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h2>Relaciones sin asignar</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Departamento</th>
            <th>Categoría</th>
          </tr>
        </thead>
        <tbody>
          {getUnassignedDepartments().map((dc, index) => (
            <tr key={index}>
              <td>{dc.departamento}</td>
              <td>{dc.categoria}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editing ? 'Editar Asignación' : 'Añadir Asignación'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group controlId="departamento">
              <Form.Label>Departamento</Form.Label>
              <Form.Control
                as="select"
                value={newAsignacion.departamento}
                onChange={(e) => handleAsignacionChange('departamento', e.target.value)}
              >
                <option value="">Seleccione un departamento</option>
                {getUniqueDepartments().map(departamento => (
                  <option key={departamento} value={departamento}>
                    {departamento}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="categoria">
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                as="select"
                value={newAsignacion.categoria}
                onChange={(e) => handleAsignacionChange('categoria', e.target.value)}
                disabled={!newAsignacion.departamento}
              >
                <option value="">Seleccione una categoría</option>
                {newAsignacion.departamento && getCategoriesForDepartment(newAsignacion.departamento).map(categoria => (
                  <option key={categoria} value={categoria}>
                    {categoria}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="modelo">
              <Form.Label>Modelo</Form.Label>
              <Form.Control
                as="select"
                value={newAsignacion.modelo}
                onChange={(e) => handleAsignacionChange('modelo', e.target.value)}
              >
                <option value="">Seleccione un modelo</option>
                {modelos.map(modelo => (
                  <option key={modelo._id} value={modelo._id}>
                    {modelo.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveAsignacion}>
            {editing ? 'Guardar Cambios' : 'Guardar Asignación'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default RelacionModelosDepartamentos;
