import React from 'react';
import { Container } from 'react-bootstrap';

function AsignacionModelos() {
  return (
    <Container>
      <h1>Asignación de Modelos</h1>
      <p>Esta es la página de asignación de modelos.</p>
    </Container>
  );
}

export default AsignacionModelos;
