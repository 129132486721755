import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import TopBar from './components/TopBar';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Ordenadores from './pages/Ordenadores';
import ModelosEstandarizados from './pages/ModelosEstandarizados';
import Oficinas from './pages/Oficinas';
import Usuarios from './pages/Usuarios';
import AddUsuario from './pages/AddUsuario';
import Empleados from './pages/Empleados';
import AsignacionModelos from './pages/AsignacionModelos';
import Alertas from './pages/Alertas';
import Login from './pages/Login';
import Exportar from './pages/Exportar';
import RelacionModelosDepartamentos from './pages/RelacionModelosDepartamentos';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import axios from './axiosConfig';

const App = () => {
  const [auth, setAuth] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/auth/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAuth(true);
      setUserRole(response.data.data.rol);
    } catch (error) {
      console.error('Error fetching user data', error);
      setAuth(false);
      setUserRole(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserData(token);
    } else {
      setAuth(false);
      setUserRole(null);
      setLoading(false);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth(false);
    setUserRole(null);
    axios.defaults.headers.common['Authorization'] = '';
  };

  const handleToggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const PrivateRoute = ({ element: Component, role, ...rest }) => (
    auth && (role ? userRole === role : true) ? <Component {...rest} /> : <Navigate to="/login" />
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div>
        {auth && <TopBar setAuth={setAuth} onLogout={handleLogout} />}
        <Container fluid>
          <Row>
            <Col xs={sidebarCollapsed ? 1 : 3} md={sidebarCollapsed ? 1 : 2} className="sidebar-container">
              {auth && <Sidebar userRole={userRole} onToggleSidebar={handleToggleSidebar} />}
            </Col>
            <Col xs={sidebarCollapsed ? 11 : 9} md={sidebarCollapsed ? 11 : 10}>
              <Routes>
                <Route path="/login" element={<Login setAuth={setAuth} />} />
                <Route path="/" element={<PrivateRoute element={Dashboard} />} />
                <Route path="/usuarios" element={<PrivateRoute element={Usuarios} role="Admin" />} />
                <Route path="/add-usuario" element={<PrivateRoute element={AddUsuario} role="Admin" />} />
                <Route path="/empleados" element={<PrivateRoute element={Empleados} role="Admin" />} />
                <Route path="/modelos" element={<PrivateRoute element={ModelosEstandarizados} role="Admin" />} />
                <Route path="/ordenadores" element={<PrivateRoute element={Ordenadores} />} />
                <Route path="/asignacion-modelos" element={<PrivateRoute element={AsignacionModelos} />} />
                <Route path="/oficinas" element={<PrivateRoute element={Oficinas} />} />
                <Route path="/alertas" element={<PrivateRoute element={Alertas} role="Admin" />} />
                <Route path="/exportar" element={<PrivateRoute element={Exportar} role="Admin" />} />
                <Route path="/relacion-modelos-departamentos" element={<PrivateRoute element={RelacionModelosDepartamentos} />} />
              </Routes>
            </Col>
          </Row>
        </Container>
      </div>
    </Router>
  );
};

export default App;
