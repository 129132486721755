import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig'; // Importa la instancia configurada de Axios
import { Table, Container, Button, Modal, Form, Alert, Pagination, DropdownButton, Dropdown } from 'react-bootstrap';
import * as XLSX from 'xlsx';

const Empleados = () => {
  const [empleados, setEmpleados] = useState([]);
  const [oficinas, setOficinas] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [currentEmpleado, setCurrentEmpleado] = useState(null);
  const [empleadoIdToDelete, setEmpleadoIdToDelete] = useState(null);
  const [modalError, setModalError] = useState(''); // Nuevo estado para los errores del modal
  const [success, setSuccess] = useState('');
  const [newEmpleado, setNewEmpleado] = useState({
    employeeId: '',
    departamentos: '',
    categoria: '',
    oficina: '',
    activo: true,
  });
  const [filters, setFilters] = useState({
    employeeId: '',
    departamentos: '',
    categoria: '',
    oficina: '',
    activo: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const fetchEmpleados = async () => {
      try {
        const { data } = await axios.get('/empleados');
        setEmpleados(data);
      } catch (error) {
        console.error('Error fetching empleados', error);
        if (error.response && error.response.data && error.response.data.message) {
          setModalError(error.response.data.message);
        } else {
          setModalError('Error fetching empleados');
        }
      }
    };

    const fetchOficinas = async () => {
      try {
        const { data } = await axios.get('/oficinas');
        setOficinas(data);
      } catch (error) {
        console.error('Error fetching oficinas', error);
        if (error.response && error.response.data && error.response.data.message) {
          setModalError(error.response.data.message);
        } else {
          setModalError('Error fetching oficinas');
        }
      }
    };

    fetchEmpleados();
    fetchOficinas();
  }, []);

  const handleAddEmpleado = () => {
    setNewEmpleado({
      employeeId: '',
      departamentos: '',
      categoria: '',
      oficina: '',
      activo: true,
    });
    setModalError(''); // Limpiar error previo
    setShowAddModal(true);
  };

  const handleEditEmpleado = (empleado) => {
    setCurrentEmpleado(empleado);
    setNewEmpleado({
      employeeId: empleado.employeeId,
      departamentos: empleado.departamentos.join(', '),
      categoria: empleado.categoria,
      oficina: empleado.oficina._id,
      activo: empleado.activo,
    });
    setModalError('');
    setShowEditModal(true);
  };

  const handleDeleteEmpleado = (id) => {
    setEmpleadoIdToDelete(id);
    setShowDeleteModal(true);
  };

  const handleAddEmpleadoSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post('/empleados', {
        ...newEmpleado,
        departamentos: newEmpleado.departamentos.split(',').map(dep => dep.trim()),
      });

      // Encontrar la oficina en la lista de oficinas para agregarla al estado de empleados
      const oficina = oficinas.find(ofi => ofi._id === data.oficina);
      data.oficina = oficina;

      setEmpleados([...empleados, data]);
      setShowAddModal(false);
      setSuccess('Empleado añadido con éxito');
      setNewEmpleado({
        employeeId: '',
        departamentos: '',
        categoria: '',
        oficina: '',
        activo: true,
      });
    } catch (error) {
      console.error('Error adding empleado', error);
      if (error.response && error.response.data && error.response.data.message) {
        setModalError(error.response.data.message);
      } else {
        setModalError('Error adding empleado');
      }
    }
  };

  const handleEditEmpleadoSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.put(`/empleados/${currentEmpleado._id}`, {
        ...newEmpleado,
        departamentos: newEmpleado.departamentos.split(',').map(dep => dep.trim()),
      });

      // Encontrar la oficina en la lista de oficinas para agregarla al estado de empleados
      const oficina = oficinas.find(ofi => ofi._id === data.oficina);
      data.oficina = oficina;

      const updatedEmpleados = empleados.map((empleado) =>
        empleado._id === currentEmpleado._id ? data : empleado
      );
      setEmpleados(updatedEmpleados);
      setShowEditModal(false);
      setSuccess('Empleado actualizado con éxito');
      setNewEmpleado({
        employeeId: '',
        departamentos: '',
        categoria: '',
        oficina: '',
        activo: true,
      });
      setCurrentEmpleado(null);
    } catch (error) {
      console.error('Error updating empleado', error);
      if (error.response && error.response.data && error.response.data.message) {
        setModalError(error.response.data.message);
      } else {
        setModalError('Error updating empleado');
      }
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`/empleados/${empleadoIdToDelete}`);
      setEmpleados(empleados.filter((empleado) => empleado._id !== empleadoIdToDelete));
      setShowDeleteModal(false);
      setSuccess('Empleado eliminado con éxito');
    } catch (error) {
      console.error('Error deleting empleado', error);
      setModalError(error.response.data.message);
      setShowDeleteModal(false);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setEmpleadoIdToDelete(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEmpleado({ ...newEmpleado, [name]: value });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet);

      try {
        const response = await axios.post('/empleados/import', data);
        const updatedEmpleados = [...empleados, ...response.data].map((emp) => {
          const oficina = oficinas.find(ofi => ofi._id === emp.oficina) || emp.oficina;
          emp.oficina = oficina;
          return emp;
        });
        setEmpleados(updatedEmpleados);
        setShowImportModal(false); // Cerrar el modal después de la importación exitosa
        setSuccess('Datos importados con éxito');
      } catch (error) {
        console.error('Error importing data', error);
        if (error.response && error.response.data && error.response.data.message) {
          setModalError(error.response.data.message);
        } else {
          setModalError('Error importing data');
        }
      }
    };
    reader.readAsBinaryString(file);
  };

  const handleDownloadSample = () => {
    const sampleData = [
      { employeeId: 'E123', departamentos: 'Departamento 1, Departamento 2', categoria: 'Categoría 1', oficina: 'Oficina 1', activo: 'Sí' },
      { employeeId: 'E124', departamentos: 'Departamento 3', categoria: 'Categoría 2', oficina: 'Oficina 2', activo: 'No' },
    ];
    const worksheet = XLSX.utils.json_to_sheet(sampleData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Empleados');
    XLSX.writeFile(workbook, 'empleados_muestra.xlsx');
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (numberOfItems) => {
    setItemsPerPage(numberOfItems);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleClearFilter = (field) => {
    setFilters({ ...filters, [field]: '' });
  };

  const filteredEmpleados = empleados.filter((empleado) => {
    return (
      (filters.employeeId === '' || empleado.employeeId?.toLowerCase().includes(filters.employeeId.toLowerCase())) &&
      (filters.departamentos === '' || empleado.departamentos.join(', ').toLowerCase().includes(filters.departamentos.toLowerCase())) &&
      (filters.categoria === '' || empleado.categoria?.toLowerCase().includes(filters.categoria.toLowerCase())) &&
      (filters.oficina === '' || empleado.oficina?.nombre?.toLowerCase().includes(filters.oficina.toLowerCase())) &&
      (filters.activo === '' || (filters.activo === 'Sí' ? empleado.activo : !empleado.activo))
    );
  });

  // Paginación
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredEmpleados.slice(indexOfFirstItem, indexOfLastItem);

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredEmpleados.length / itemsPerPage);
    const pageNumbers = [];
    let startPage, endPage;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item className="mt-2" key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    return (
      <Pagination>
        {currentPage > 1 && (
          <>
            <Pagination.First onClick={() => handlePageChange(1)} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
          </>
        )}
        {pageNumbers}
        {currentPage < totalPages && (
          <>
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} />
          </>
        )}
      </Pagination>
    );
  };

  const getUniqueDepartments = () => {
    const allDepartments = empleados.flatMap(empleado => empleado.departamentos);
    return Array.from(new Set(allDepartments));
  };
  
  const getFilterOptions = (field) => {
    const options = new Set(
      empleados.map(empleado => field === 'oficina' ? empleado[field]?.nombre : empleado[field]).filter(Boolean)
    );
    return Array.from(options);
  };
  
  const employeeIdOptions = getFilterOptions('employeeId');
  const departamentosOptions = getUniqueDepartments(); 
  const categoriaOptions = getFilterOptions('categoria');
  const oficinaOptions = getFilterOptions('oficina');
  const activoOptions = ['Sí', 'No'];

  return (
    <Container>
      <h1>Empleados</h1>
      {success && <Alert variant="success">{success}</Alert>}
      <Button variant="primary" onClick={handleAddEmpleado} className="mb-3">
        Añadir Empleado
      </Button>
      <Button variant="secondary" onClick={() => setShowImportModal(true)} className="mb-3 ms-3">
        Importar Empleados
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Acciones</th>
            <th>
              <Dropdown>
                <Dropdown.Toggle variant={filters.employeeId ? "primary" : "link"} id="dropdown-employeeId" className="d-flex align-items-center justify-content-between">
                  Employee ID
                  {filters.employeeId && <Button variant="danger" size="sm" className="ml-2" onClick={() => handleClearFilter('employeeId')}>X</Button>}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Form.Control
                    type="text"
                    name="employeeId"
                    value={filters.employeeId}
                    onChange={handleFilterChange}
                    placeholder="Filtrar"
                    className="mx-3 my-2 w-auto"
                  />
                  {employeeIdOptions.map((option, index) => (
                    <Dropdown.Item key={index} onClick={() => setFilters({ ...filters, employeeId: option })}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th>
              <Dropdown>
                <Dropdown.Toggle variant={filters.departamentos ? "primary" : "link"} id="dropdown-departamentos" className="d-flex align-items-center justify-content-between">
                  Departamentos
                  {filters.departamentos && <Button variant="danger" size="sm" className="ml-2" onClick={() => handleClearFilter('departamentos')}>X</Button>}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Form.Control
                    type="text"
                    name="departamentos"
                    value={filters.departamentos}
                    onChange={handleFilterChange}
                    placeholder="Filtrar"
                    className="mx-3 my-2 w-auto"
                  />
                  {departamentosOptions.map((option, index) => (
                    <Dropdown.Item key={index} onClick={() => setFilters({ ...filters, departamentos: option })}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th>
              <Dropdown>
                <Dropdown.Toggle variant={filters.categoria ? "primary" : "link"} id="dropdown-categoria" className="d-flex align-items-center justify-content-between">
                  Categoría
                  {filters.categoria && <Button variant="danger" size="sm" className="ml-2" onClick={() => handleClearFilter('categoria')}>X</Button>}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Form.Control
                    type="text"
                    name="categoria"
                    value={filters.categoria}
                    onChange={handleFilterChange}
                    placeholder="Filtrar"
                    className="mx-3 my-2 w-auto"
                  />
                  {categoriaOptions.map((option, index) => (
                    <Dropdown.Item key={index} onClick={() => setFilters({ ...filters, categoria: option })}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th>
              <Dropdown>
                <Dropdown.Toggle variant={filters.oficina ? "primary" : "link"} id="dropdown-oficina" className="d-flex align-items-center justify-content-between">
                  Oficina
                  {filters.oficina && <Button variant="danger" size="sm" className="ml-2" onClick={() => handleClearFilter('oficina')}>X</Button>}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Form.Control
                    type="text"
                    name="oficina"
                    value={filters.oficina}
                    onChange={handleFilterChange}
                    placeholder="Filtrar"
                    className="mx-3 my-2 w-auto"
                  />
                  {oficinaOptions.map((option, index) => (
                    <Dropdown.Item key={index} onClick={() => setFilters({ ...filters, oficina: option })}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th>
              <Dropdown>
                <Dropdown.Toggle variant={filters.activo ? "primary" : "link"} id="dropdown-activo" className="d-flex align-items-center justify-content-between">
                  Activo
                  {filters.activo && <Button variant="danger" size="sm" className="ml-2" onClick={() => handleClearFilter('activo')}>X</Button>}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Form.Control
                    as="select"
                    name="activo"
                    value={filters.activo}
                    onChange={handleFilterChange}
                    className="mx-3 my-2 w-auto"
                  >
                    <option value="">Todos</option>
                    {activoOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </Dropdown.Menu>
              </Dropdown>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((empleado) => (
            <tr key={empleado._id}>
              <td>
                <Button variant="warning" onClick={() => handleEditEmpleado(empleado)}>
                  Editar
                </Button>{' '}
                <Button variant="danger" onClick={() => handleDeleteEmpleado(empleado._id)}>
                  Eliminar
                </Button>
              </td>
              <td>{empleado.employeeId}</td>
              <td>{empleado.departamentos.join(', ')}</td>
              <td>{empleado.categoria}</td>
              <td>{empleado.oficina?.nombre}</td>
              <td>{empleado.activo ? 'Sí' : 'No'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <DropdownButton id="dropdown-basic-button" title={`Mostrar ${itemsPerPage}`}>
        <Dropdown.Item onClick={() => handleItemsPerPageChange(10)}>10</Dropdown.Item>
        <Dropdown.Item onClick={() => handleItemsPerPageChange(25)}>25</Dropdown.Item>
        <Dropdown.Item onClick={() => handleItemsPerPageChange(50)}>50</Dropdown.Item>
        <Dropdown.Item onClick={() => handleItemsPerPageChange(100)}>100</Dropdown.Item>
      </DropdownButton>
      {renderPagination()}

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Añadir Empleado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalError && <Alert variant="danger">{modalError}</Alert>}
          <Form onSubmit={handleAddEmpleadoSubmit}>
            <Form.Group controlId="employeeId">
              <Form.Label>Employee ID</Form.Label>
              <Form.Control
                type="text"
                name="employeeId"
                value={newEmpleado.employeeId}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="departamentos">
              <Form.Label>Departamentos</Form.Label>
              <Form.Control
                type="text"
                name="departamentos"
                value={newEmpleado.departamentos}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="categoria">
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                type="text"
                name="categoria"
                value={newEmpleado.categoria}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="oficina">
              <Form.Label>Oficina</Form.Label>
              <Form.Control
                as="select"
                name="oficina"
                value={newEmpleado.oficina}
                onChange={handleInputChange}
                required
              >
                <option value="">Seleccione una oficina</option>
                {oficinas.map((oficina) => (
                  <option key={oficina._id} value={oficina._id}>
                    {oficina.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="activo">
              <Form.Check
                type="checkbox"
                label="Activo"
                name="activo"
                checked={newEmpleado.activo}
                onChange={(e) => setNewEmpleado({ ...newEmpleado, activo: e.target.checked })}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Añadir Empleado
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Empleado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalError && <Alert variant="danger">{modalError}</Alert>}
          <Form onSubmit={handleEditEmpleadoSubmit}>
            <Form.Group controlId="employeeId">
              <Form.Label>Employee ID</Form.Label>
              <Form.Control
                type="text"
                name="employeeId"
                value={newEmpleado.employeeId}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="departamentos">
              <Form.Label>Departamentos</Form.Label>
              <Form.Control
                type="text"
                name="departamentos"
                value={newEmpleado.departamentos}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="categoria">
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                type="text"
                name="categoria"
                value={newEmpleado.categoria}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="oficina">
              <Form.Label>Oficina</Form.Label>
              <Form.Control
                as="select"
                name="oficina"
                value={newEmpleado.oficina}
                onChange={handleInputChange}
                required
              >
                <option value="">Seleccione una oficina</option>
                {oficinas.map((oficina) => (
                  <option key={oficina._id} value={oficina._id}>
                    {oficina.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="activo">
              <Form.Check
                type="checkbox"
                label="Activo"
                name="activo"
                checked={newEmpleado.activo}
                onChange={(e) => setNewEmpleado({ ...newEmpleado, activo: e.target.checked })}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Actualizar Empleado
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de que desea eliminar este empleado?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showImportModal} onHide={() => setShowImportModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Importar Empleados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalError && <Alert variant="danger">{modalError}</Alert>}
          <Button variant="info" onClick={handleDownloadSample}>
            Descargar Archivo de Muestra
          </Button>
          <Form.Group controlId="fileUpload" className="mt-3">
            <Form.Label>Subir Archivo Excel</Form.Label>
            <Form.Control type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
          </Form.Group>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Empleados;
