import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AddUsuario = () => {
  const [nombre, setNombre] = useState('');
  const [rol, setRol] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [oficinas, setOficinas] = useState([]); // Estado para la lista de oficinas
  const [selectedOficinas, setSelectedOficinas] = useState([]); // Estado para las oficinas seleccionadas
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOficinas = async () => {
      try {
        const { data } = await axios.get('/oficinas');
        setOficinas(data);
      } catch (error) {
        console.error('Error fetching oficinas', error);
      }
    };

    fetchOficinas();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post('/usuarios', {
        nombre,
        rol,
        email,
        password,
        oficinas: selectedOficinas
      });
      setSuccess('Usuario creado con éxito');
      setError('');
      setNombre('');
      setRol('');
      setEmail('');
      setPassword('');
      setSelectedOficinas([]);
      setTimeout(() => {
        navigate('/usuarios');
      }, 2000); // Redirigir a la página de usuarios después de 2 segundos
    } catch (err) {
      setError(err.response.data.message);
      setSuccess('');
    }
  };

  const handleOfficeChange = (e) => {
    const options = e.target.options;
    const selected = [];
    for (const option of options) {
      if (option.selected) {
        selected.push(option.value);
      }
    }
    setSelectedOficinas(selected);
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h1>Añadir Usuario</h1>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="nombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese el nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="rol">
              <Form.Label>Rol</Form.Label>
              <Form.Control
                as="select"
                value={rol}
                onChange={(e) => setRol(e.target.value)}
                required
              >
                <option value="">Seleccione un rol</option>
                <option value="Admin">Admin</option>
                <option value="User">User</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Ingrese el email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Ingrese la contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="oficinas">
              <Form.Label>Oficinas</Form.Label>
              <Form.Control
                as="select"
                multiple
                value={selectedOficinas}
                onChange={handleOfficeChange}
              >
                {oficinas.map((oficina) => (
                  <option key={oficina._id} value={oficina._id}>
                    {oficina.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Añadir Usuario
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AddUsuario;
