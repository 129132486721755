import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { Container, Table, Alert } from 'react-bootstrap';

const Alertas = () => {
  const [alertas, setAlertas] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchAlertas = async () => {
      try {
        const { data } = await axios.get('/alertas');
        setAlertas(data);
      } catch (error) {
        setError('Error fetching alerts');
      }
    };

    fetchAlertas();
  }, []);

  return (
    <Container>
      <h1>Alertas</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {alertas.length === 0 ? (
        <Alert variant="info">No hay ninguna alerta que mostrar, ¡Felicidades!</Alert>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Empleado</th>
              <th>Departamento</th>
              <th>Categoría</th>
              <th>Modelo Asignado</th>
              <th>Modelo Correcto</th>
              <th>Número de Serie</th>
              <th>Activo</th>
            </tr>
          </thead>
          <tbody>
            {alertas.map((alerta, index) => (
              <tr key={index}>
                <td>{alerta.empleado.employeeId}</td>
                <td>{alerta.empleado.departamentos.join(', ')}</td>
                <td>{alerta.empleado.categoria}</td>
                <td>{alerta.ordenador.modelo}</td>
                <td>{alerta.modeloCorrecto}</td>
                <td>{alerta.ordenador.numeroSerie}</td>
                <td>{alerta.empleado.activo ? 'Sí' : 'No'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default Alertas;
